<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/scan">
          </ion-back-button>
        </ion-buttons>
        <ion-title>Baby In Room</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :class="(scanActive) ? 'scanActive' : 'scanInactive'">
      <section v-if="!scanActive">
        <ion-grid>
          <ion-row>
            <ion-col size="6">
              <!-- Mom -->
              <base-card title="Mom">
                <h5 class="" v-if="scan1.id">
                  {{ scan1.first_name }} {{ scan1.last_name }} ({{ scan1Babies }})
                </h5>

                <ion-button expand="block" @click="startScan1()" v-if="!scanActive">
                  Scan Mom
                </ion-button>
              </base-card>
            </ion-col>

            <!-- Baby -->
            <ion-col size="6">
              <base-card title="Baby">
                <h5 class="" v-if="scan2.id">
                  {{ scan2.first_name }} {{ scan2.last_name }} ({{ scan2Babies }})
                </h5>

                <ion-button expand="block" @click="startScan2()" v-if="!scanActive">
                  Scan Baby
                </ion-button>
              </base-card>
            </ion-col>

            <!-- Match -->
            <ion-col size="12" v-if="scansComplete">
              <base-card title="">
                <div>
                  <h1 class="ion-text-center" v-if="scansMatch">
                    ✅<br />
                    Match
                  </h1>

                  <h1 class="ion-text-center" v-if="!scansMatch">
                    ❌<br />
                    Scans Do Not Match
                  </h1>
                </div>

                <div v-if="scansMatch">
                  <base-input label-text="Choose a baby">
                    <select v-model="selectedBabyId" class="regular-select">
                      <option :value="relation.user_to.id" v-for="relation in scan1.relations_from"
                        :key="relation.user_to.iid">
                        {{ relation.user_to.first_name }} {{ relation.user_to.last_name }}
                      </option>
                    </select>
                  </base-input>

                  <ion-button expand="block"
                    :router-link="`/users/${selectedBabyId}/activities/create?type=baby_in_guest_room&scanned=1`">
                    Record Baby In Room
                  </ion-button>
                </div>
              </base-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonToolbar, IonButtons, IonTitle, IonContent, IonButton, IonBackButton, IonGrid, IonRow, IonCol } from "@ionic/vue";

import { mapGetters } from "vuex";
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

export default {
  components: {
    IonPage, IonHeader, IonToolbar, IonButtons, IonTitle, IonContent, IonButton, IonBackButton, IonGrid, IonRow, IonCol
  },

  data() {
    return {
      scan1: {},
      scan2: {},
      result1: null,
      result2: null,
      scanActive: false,
      selectedBabyId: null
    };
  },

  computed: {
    ...mapGetters(["authUser"]),

    scansComplete() {
      if (this.scan1.id && this.scan2.id)
        return true
      return false
    },

    scansMatch() {
      if (this.scansComplete && this.scan1.id === this.scan2.id)
        return true
      else return false
    },

    scan1Babies() {
      if (this.scan1.id && this.scan1.relations_from.length > 0)
        return this.scan1.relations_from.map(x => x.user_to.first_name).join(', ');

      return "no babies"
    },

    scan2Babies() {
      if (this.scan2.id && this.scan2.relations_from.length > 0)
        return this.scan2.relations_from.map(x => x.user_to.first_name).join(', ');

      return "no babies"
    },
  },

  ionViewWillEnter() {
    // this.fetchScan1(44)
    // this.fetchScan2(44)
  },

  ionViewWillLeave() {
    this.stopScan()
  },

  methods: {
    async startScan1() {
      console.log('Scan 1 Started')
      // Check camera permission
      // This is just a simple example, check out the better checks below
      await BarcodeScanner.checkPermission({ force: true });

      // make background of WebView transparent
      // note: if you are using ionic this might not be enough, check below
      // BarcodeScanner.hideBackground();

      this.scanActive = true

      const result = await BarcodeScanner.startScan(); // start scanning and wait for a result

      // if the result has content
      if (result.hasContent) {
        console.log(`scanner started - result is ${result.content}`); // log the raw scanned content
        this.result1 = result.content

        this.fetchScan1(this.result1)

        this.stopScan()
      }
    },

    async startScan2() {
      console.log('Scan 2 Started')
      // Check camera permission
      // This is just a simple example, check out the better checks below
      await BarcodeScanner.checkPermission({ force: true });

      // make background of WebView transparent
      // note: if you are using ionic this might not be enough, check below
      // BarcodeScanner.hideBackground();

      this.scanActive = true

      const result = await BarcodeScanner.startScan(); // start scanning and wait for a result

      // if the result has content
      if (result.hasContent) {
        console.log(`scanner started - result is ${result.content}`); // log the raw scanned content
        this.result2 = result.content

        this.fetchScan2(this.result2)

        this.stopScan()
      }
    },

    async stopScan() {
      await BarcodeScanner.stopScan(); // start scanning and wait for a result
      this.scanActive = false
    },

    async fetchScan1(id) {
      await this.axios
        .get(`${process.env.VUE_APP_API}/users/${id}`)
        .then((response) => {
          this.scan1 = response.data.user;
        })
        .catch((error) => { console.log(error); });
    },

    async fetchScan2(id) {
      await this.axios
        .get(`${process.env.VUE_APP_API}/users/${id}`)
        .then((response) => {
          this.scan2 = response.data.user;

          if (this.scan2.relations_from.length > 0)
            this.selectedBabyId = this.scan2.relations_from[this.scan2.relations_from.length - 1].user_to.id
        })
        .catch((error) => { console.log(error); });
    }
  },
};
</script>

<style>
ion-toolbar {
  --background: #fff;
}

.scanActive {
  --background: transparent;
  --ion-background-color: transparent;
}

.scanInactive {
  --background: var(--ion-color-light);
  /* --ion-background-color: var(--ion-color-light); */
}
</style>